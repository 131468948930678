import React, { useEffect } from "react";
import Navbar from "../components/Navbar/Navbar";
import Footer from "../sections/Footer/Footer";
import SectionTitle from "../components/SectionTitle/SectionTitle";
import { WrapperPrice } from "./style";
import { useTranslation } from "react-i18next";

const About = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    window.scrollTo({ top: "0px" });
  }, []);
  return (
    <>
      <Navbar />
      <div className="wrapper">
        <section
          className="emergency-section"
          data-aos="fade-up"
          data-aos-duration="2000"
          style={{ margin: "150px 50px 0px 50px" }}
        >
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-6">
                <img
                  width={400}
                  src={
                    "https://optim.tildacdn.com/tild3534-3531-4466-b462-353434383162/-/resize/734x/-/format/webp/5F6A9264_2.png"
                  }
                  alt="Emergency"
                />
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="emergency-text">
                  <SectionTitle
                    title={
                      i18n.language == "uz"
                        ? "MRT ni yuboring"
                        : "Отправьте МРТ позвоночника"
                    }
                  />

                  <p>
                    {i18n.language == "uz"
                      ? "va klinikamizda grija rezorbsiyasi ehtimoli va jarrohliksiz davolanish imkoniyati haqida ekspert xulosasini oling"
                      : " и получите экспертное мнение о вероятности резорбции грыжи и возможности безоперационного лечения в нашей клинике"}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            maxWidth: "1290px",
            gap: "24px",
            padding: "0 10px",
            margin: "50px 0 0 0",
          }}
        >
          <SectionTitle
            title={
              i18n.language == "uz"
                ? "Bizning mutaxassislarimizga MRTni qanday yuborish kerak"
                : "Как отправить МРТ нашим специалистам"
            }
          />
          <WrapperPrice.Flex>
            <WrapperPrice.Info>
              <WrapperPrice.Center>
                <img
                  width={60}
                  height={60}
                  src={
                    "https://optim.tildacdn.com/tild6331-3836-4431-a230-666336393335/-/cover/60x60/center/center/-/format/webp/attached-file.png"
                  }
                  alt=""
                />
              </WrapperPrice.Center>
              <p>
                {i18n.language == "uz"
                  ? "Xat yozing yoki MRTga havolani qo'shing"
                  : "Напишите письмо и прикрепите архив или ссылку на МРТ"}
              </p>
            </WrapperPrice.Info>
            <WrapperPrice.Info>
              <WrapperPrice.Center>
                <img
                  width={60}
                  height={60}
                  src={
                    "https://optim.tildacdn.com/tild6665-6265-4065-b330-323931636462/-/cover/60x60/center/center/-/format/webp/email1.png"
                  }
                  alt=""
                />
              </WrapperPrice.Center>

              {i18n.language == "uz" ? (
                <p>
                  <span>@eldor_nevro</span> telegram manziliga yuboring
                </p>
              ) : (
                <p>
                  {" "}
                  Отправьте на telegram <span>@eldor_nevro</span>
                </p>
              )}
            </WrapperPrice.Info>

            <WrapperPrice.Info>
              <WrapperPrice.Center>
                <img
                  width={60}
                  height={60}
                  src={
                    "https://optim.tildacdn.com/tild3261-3561-4264-a634-363032643238/-/cover/60x60/center/center/-/format/webp/chat1.png"
                  }
                  alt=""
                />
              </WrapperPrice.Center>
              <p>
                {i18n.language == "uz"
                  ? 'E-pochtangizga 2-10 kun ichida javob beramiz. Agar sizga tezroq kerak bo\'lsa, mavzuga "Shoshilinch" deb yozing.'
                  : "Ответим на вашу почту в течение 2–10 дней. Если нужно быстрее, напишите в теме письма — «Срочно»"}
              </p>
            </WrapperPrice.Info>
          </WrapperPrice.Flex>
          {/* 
          <WrapperPrice.Flex>
            <WrapperPrice.Info>
              <h5>
                {i18n.language == "uz"
                  ? "Maktubda quyidagilarni ko'rsatishni unutmang:"
                  : " В письме обязательно укажите:"}
              </h5>
              <p>
                {i18n.language == "uz"
                  ? "- Shaxsiy ma'lumotlar: to'liq ism, yosh, shahar"
                  : "  - Личные данные: ФИО, возраст, город"}
              </p>
              <p>{i18n.language == "uz" ? "- Telefon" : "- Телефон"}</p>
              <p>
                {i18n.language == "uz"
                  ? "- MRT vaqtidagi shikoyatlar"
                  : "- Жалобы на момент выполнения МРТ"}
              </p>
              <p>
                {i18n.language == "uz"
                  ? "- Bugungi kungacha shikoyatlar"
                  : " -Жалобы на сегодняшний день"}
              </p>
              <p>
                {i18n.language == "uz"
                  ? "- Bugungi kungacha shikoyatlar"
                  : "- Какое лечение уже получали или получаете сейчас"}
              </p>
              <p>
                - Подтверждение, что рассматриваете клинику в Волгограде для
                лечения
              </p>
            </WrapperPrice.Info>
            <WrapperPrice.Info>
              <h5>Требования к МРТ:</h5>
              <p>
                - Все файлы с диска МРТ добавьте в архив или загрузите в папку
                на облако Google, Yandex или Mail
              </p>
              <p>- Снимки должны быть не старше 3–6 месяцев</p>
            </WrapperPrice.Info>
          </WrapperPrice.Flex> */}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default About;
