import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar/Navbar";
import Footer from "../sections/Footer/Footer";
import SectionTitle from "../components/SectionTitle/SectionTitle";
import { Wrapper } from "../sections/Emergency/style";
import { WrapperPrice } from "./style";
import { useTranslation } from "react-i18next";
import Grija from "../assets/n1.png";
import Grija1 from "../assets/n2.png";
const About = () => {
  const [active, setActive] = useState(0);
  const { i18n } = useTranslation();
  const opts = {
    height: "390",
    width: "640",
    playerVars: {
      autoplay: 0,
    },
  };
  const items = [
    {
      contex: (
        <Wrapper.Flex>
          <WrapperPrice.ImgBox>
            <WrapperPrice.Img src={Grija} />
            <WrapperPrice.Img src={Grija1} />
          </WrapperPrice.ImgBox>

          <div>
            <h3>
              {i18n.language == "uz"
                ? "Bemor: Umurtqaning L5-S1 sohasida 11.3 mm grija"
                : "Пациент: Межпозвоночная грыжа поясничного отдела на уровне L5-S1 11.3 мм"}
            </h3>
            <p style={{ color: "red", marginTop: "5px" }}>
              {i18n.language == "uz" ? "Davolanishdan oldin:" : "До лечения:"}
            </p>
            <p>
              {i18n.language == "uz"
                ? "- Belni tik tutaolmaslik"
                : "- Невозможность выпрямить осанку и его тугоподвижность"}
            </p>
            <p>
              {i18n.language == "uz"
                ? "- O'tirganda va yurganda kuchli og'riqlar"
                : "- Сильные боли в сидячем положении и ходьбе"}
            </p>
            <p>
              {i18n.language == "uz"
                ? "- Oyoq harakatini cheklanishi"
                : "- Ограничение движение ног"}
            </p>
            <p style={{ color: "#57926d", marginTop: "5px" }}>
              {i18n.language == "uz"
                ? "1 kursdan keyin:"
                : "После 1 курса лечения:"}
            </p>
            <p>
              {i18n.language == "uz"
                ? "- O'tirgan paytdaki og'riqlar yuqoldi"
                : "- Прошли боли в сидячем положении"}
            </p>
            <p>
              {i18n.language == "uz"
                ? "- Bel tiklandi"
                : "- Пациент ходил уже с ровной осанкой"}
            </p>

            <p>
              {" "}
              {i18n.language == "uz"
                ? "- Og'riqlar sezirarli darajada kamaydi"
                : "- Боли значительно уменьшились"}
            </p>
            <p style={{ color: "#57926d", marginTop: "5px" }}>
              {i18n.language == "uz"
                ? "3 kursdan keyin:"
                : "После 3 курса лечения:"}
            </p>
            <p>
              {i18n.language == "uz"
                ? "- Grija surildi"
                : "- Грыжа рассосалась"}
            </p>
            <p>
              {i18n.language == "uz"
                ? "- Og'riqlar butkul yuqoldi"
                : "- Боли исчезли полностью"}
            </p>
            <p>
              {i18n.language == "uz"
                ? "- Bemor kundalik og'riqlarsiz xayotga qaytdi 😊"
                : "- Пациент вернулся к прежней жизни без боли😊"}
            </p>
          </div>
        </Wrapper.Flex>
      ),
    },
    {
      contex: (
        <Wrapper.Flex>
          <div style={{ width: "100%", height: "350px" }}>
            <iframe
              width="100%"
              height="350"
              style={{ borderRadius: "10px" }}
              src="https://www.youtube.com/embed/a7wklUthF5U"
              title="Бел грыжаси туфайли юролмай колган бемор"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </div>
          <div>
            <h3>
              {i18n.language == "uz"
                ? "Bemor: Umurtqaning L4-L5 sohasida 6 mm grija"
                : "Пациент: грыжа поясничного сегмента L4-L5 6 мм"}
            </h3>
            <p style={{ color: "red", marginTop: "5px" }}>
              {i18n.language == "uz" ? "Davolanishdan oldin:" : "До лечения:"}
            </p>
            <p>
              {i18n.language == "uz"
                ? "- Oyoqdagi kuchli og'riqlar"
                : "- Сильные боли в ногах и их ограничение в движении"}
            </p>
            <p>
              {i18n.language == "uz"
                ? "- Belni tik tutaolmaslik"
                : "- Невозможность держать осанку ровно"}
            </p>
            <p>
              {i18n.language == "uz"
                ? "- Yurganda kuchli bel og'riqlari"
                : "- Боли в спине и ноге увеличиваются при ходьбе"}
            </p>
            <p style={{ color: "#57926d", marginTop: "5px" }}>
              {i18n.language == "uz"
                ? "1 kursdan keyin:"
                : "После 1 курса лечения:"}
            </p>
            <p>
              {i18n.language == "uz"
                ? "- Og'riqlar 70 - 90% kamaydi"
                : "- Боли уменьшились на 70-90%"}
            </p>
            <p>
              {i18n.language == "uz"
                ? "- Oyoq xarakati tiklandi"
                : "- Движение ног восстановилось "}
            </p>

            <p>
              {i18n.language == "uz"
                ? "- Bemorda operatsiyaga extiyoj qolmadi 😊"
                : "- Пациент избавился от болей без операции 😊"}
            </p>
          </div>
        </Wrapper.Flex>
      ),
    },
    {
      contex: (
        <Wrapper.Flex>
          <div style={{ width: "100%", height: "350px" }}>
            <iframe
              width="100%"
              height="350"
              style={{ borderRadius: "10px" }}
              src="https://www.youtube.com/embed/-Q9pZ7kUhZo"
              title="1 кисм. Буйин грыжаси билан мурожаат килган бемор.Мурожаат учун : 980777287 ; 980777127"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </div>
          <div>
            <h3>
              {i18n.language == "uz"
                ? "Bemor: Bo'ying grijasi bilan murojat qildi"
                : "Пациентка: Обратилась с грыжей в шейном отделе."}
            </h3>
            <p style={{ color: "red", marginTop: "5px" }}>
              {i18n.language == "uz" ? "Davolanishdan oldin:" : "До лечения:"}
            </p>
            <p>
              {i18n.language == "uz"
                ? "- Kuchli bosh og'riqlar"
                : "- Сильные головные боли"}
            </p>
            <p>
              {i18n.language == "uz"
                ? "- Qon bosimining o'ynashi"
                : "- Скачка артериального давления"}
            </p>
            <p>
              {i18n.language == "uz"
                ? "- Qo'l harakatini cheklanishi va kuchli og'riqlar"
                : "- Ограничение движение плечевого сустава и сильные боли в руках"}
            </p>
            <p style={{ color: "#57926d", marginTop: "5px" }}>
              {i18n.language == "uz" ? "Davolashdan so'ng:" : "После лечения:"}
            </p>
            <p>
              {i18n.language == "uz"
                ? "- Qon bosimi normal holatga qaytdi"
                : "- Давление нормализовалось"}
            </p>
            <p>
              {i18n.language == "uz"
                ? "- Bosh ogriqlar g'oyib bo'ldi va uyqu tiklandi."
                : "- Прошли головные боли, сон уровновесился"}
            </p>
            <p>
              {i18n.language == "uz"
                ? "- Qo'ldagi og'riqlar 90% kamaydi"
                : "- Прошли боли в плече на 90% и восстановилось движение плеча😊"}
            </p>
          </div>
        </Wrapper.Flex>
      ),
    },
  ];

  useEffect(() => {
    window.scrollTo({ top: "0px" });
  }, []);
  return (
    <>
      <div className="wrapper" style={{ marginBottom: "50px" }}>
        <Navbar />
        <SectionTitle
          title={
            i18n.language == "uz" ? "Davolash natijalari" : "Результаты лечения"
          }
          style={{ margin: 0 }}
        />
        <h1 style={{ margin: "0 0 20px 0" }}>
          {i18n.language == "uz" ? "Davolash natijalari" : "Результаты лечения"}
        </h1>
        <Wrapper>
          <Wrapper.Container>
            <Wrapper.Item
              start
              onClick={() => setActive(0)}
              active={active == 0}
            >
              1
            </Wrapper.Item>
            <Wrapper.Item onClick={() => setActive(1)} active={active == 1}>
              2
            </Wrapper.Item>
            <Wrapper.Item end onClick={() => setActive(2)} active={active == 2}>
              3
            </Wrapper.Item>
          </Wrapper.Container>
        </Wrapper>
        {items[active].contex}
      </div>
      <Footer />
    </>
  );
};

export default About;
