import React, { useState } from "react";
import { Wrapper } from "./styled";
import { Modal } from "antd";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

const Card = ({ img, title, desc }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const isPhone = useMediaQuery({ query: "(max-width: 550px)" });

  const showModal = () => {
    setIsModalVisible(true);
  };
  const { i18n } = useTranslation();

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <Wrapper.CardWrapper img={img}>
      <Modal
        open={isModalVisible}
        footer={null}
        width={isPhone ? 300 : 600}
        centered
        style={{ padding: "0", margin: "10px" }}
        height={"auto"}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <img
          style={{ width: "100%" }}
          src={
            img ||
            "https://optim.tildacdn.com/tild3035-6165-4135-b134-336337303061/-/resize/300x400/-/format/webp/photo.jpg"
          }
          alt=""
        />
        <h3>{title}</h3>
        <p style={{ marginTop: "20px" }}>
          {desc.split("\n").map((item, key) => (
            <React.Fragment key={key}>
              {item}
              <br />
            </React.Fragment>
          ))}
        </p>
      </Modal>
      {/*  */}

      <Wrapper.Shadow />
      <Wrapper.TextWrapper>
        <h4>{title}</h4>

        <div
          className="theme-btn"
          style={{
            margin: "60px 0 0 0",
            cursor: "pointer",
            padding: "6px 18px",
            borderRadius: "10px",
            background: "#583FBC",
          }}
          onClick={showModal}
        >
          {i18n.language == "uz" ? "Batafsil" : "Подробное"}
        </div>
      </Wrapper.TextWrapper>
    </Wrapper.CardWrapper>
  );
};

export default Card;
