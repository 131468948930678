import React from "react";
import Navbar from "../components/Navbar/Navbar";
import AboutBanner from "../sections/AboutBanner/AboutBanner";
import Faq from "../sections/Faq/Faq";
import Priority from "../sections/Priority/Priority";
import Safety from "../sections/Safety/Safety";
import Team from "../sections/Team/Team";
import BlogsPage from "./BlogsPage";
import Footer from "../sections/Footer/Footer";

const About = () => {
  return (
    <>
      <Navbar />
      <BlogsPage />
      <Footer />
    </>
  );
};

export default About;
