import React, { useEffect, useState } from "react";
import { AntdSelectLanguage } from "./style";
import { useTranslation } from "react-i18next";

const Lang_Select = () => {
  const { i18n } = useTranslation();

  const optionsLangue = [
    {
      value: "uz",
      label: (
        <div
          style={{
            display: "flex",
            gap: "10px",
            zIndex: 999,
          }}
          onClick={() => i18n.changeLanguage("uz")}
        >
          <p
            style={{
              fontWeight: "bold",
              color: "#4f7471",
              fontSize: "16px",
              marginTop: "10px",
            }}
          >
            O‘zbek
          </p>
        </div>
      ),
    },
    {
      value: "ru",
      label: (
        <div
          onClick={() => i18n.changeLanguage("ru")}
          style={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
          }}
        >
          <p
            style={{
              fontWeight: "bold",
              color: "#4f7471",
              fontSize: "16px",
              marginTop: "10px",
            }}
          >
            Русский
          </p>
        </div>
      ),
    },
  ];
  const [lan, setLan] = useState("UZB");

  useEffect(() => {
    setLan(i18n.language);
  }, [i18n.language]);
  return (
    <AntdSelectLanguage
      options={optionsLangue}
      onChange={(e, opt) => setLan(opt.value)}
      value={lan}
      style={{ zIndex: 99, border: "none" }}
      suffixIcon={
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="28"
          viewBox="0 0 24 28"
          className="ml-[10px]"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M11.5881 9.24441L13.7523 11.4086C14.2787 11.935 13.911 12.8375 13.1673 12.8375H8.83895C8.09527 12.8375 7.71925 11.935 8.24568 11.4086L10.4099 9.24441C10.7358 8.91853 11.2622 8.91853 11.5881 9.24441ZM10.4119 19.0751L8.24774 16.9109C7.72131 16.3844 8.08897 15.482 8.83265 15.482H13.161C13.9047 15.482 14.2808 16.3844 13.7543 16.9109L11.5901 19.0751C11.2642 19.4009 10.7378 19.4009 10.4119 19.0751Z"
            fill="#4F7471"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M11.5881 9.24441L13.7523 11.4086C14.2787 11.935 13.911 12.8375 13.1673 12.8375H8.83895C8.09527 12.8375 7.71925 11.935 8.24568 11.4086L10.4099 9.24441C10.7358 8.91853 11.2622 8.91853 11.5881 9.24441ZM10.4119 19.0751L8.24774 16.9109C7.72131 16.3844 8.08897 15.482 8.83265 15.482H13.161C13.9047 15.482 14.2808 16.3844 13.7543 16.9109L11.5901 19.0751C11.2642 19.4009 10.7378 19.4009 10.4119 19.0751Z"
            fill="url(#paint0_linear_638_338)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_638_338"
              x1="11"
              y1="9"
              x2="11"
              y2="19.3194"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#7C3AED" />
              <stop offset="1" stop-color="#7E22CE" />
            </linearGradient>
          </defs>
        </svg>
      }
    >
      {optionsLangue?.length &&
        optionsLangue?.map((o) => {
          return (
            <AntdSelectLanguage.Option key={o?.label} value={o?.label}>
              {o?.label}
            </AntdSelectLanguage.Option>
          );
        })}
    </AntdSelectLanguage>
  );
};

export default Lang_Select;
