import React, { useEffect } from "react";
import Navbar from "../../components/Navbar/Navbar";
import "./Contactus.scss";
import Footer from "../../sections/Footer/Footer";
import Appointment from "../../sections/Appointment/Appointment";

const Contactus = () => {
  useEffect(() => {
    window.scrollTo({ top: "0px" });
  }, []);
  return (
    <>
      <Navbar />
      <Appointment />
      <Footer />
    </>
  );
};

export default Contactus;
