import React, { useState } from "react";
import "./TestimoniCard.scss";
import { Modal, Button } from "antd";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

const TestimoniCard = ({ testimonail }) => {
  const { img, name, description, ratings, data, contact } = testimonail;
  const { i18n } = useTranslation();
  const isPhone = useMediaQuery({ query: "(max-width: 550px)" });

  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  return (
    <div className="col-lg-4 single-testimoni">
      <Modal
        open={isModalVisible}
        footer={null}
        width={isPhone ? 300 : 500}
        centered
        style={{ padding: "0", margin: "10px" }}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p style={{ marginTop: "5px", textAlign: "center" }}>{data}</p>

        <div
          style={{
            margin: "30px 0 0 0",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <p
            style={{
              color: "#fff",
              width: "fit-content",
              cursor: "pointer",
              padding: "6px 18px",
              borderRadius: "10px",
              textAlign: "center",
              background: "#583FBC",
            }}
            onClick={() => {
              window.location.pathname = "/about";
            }}
          >
            {i18n.language == "uz"
              ? "To'liq ma'lumot olish"
              : "Получить полную информацию"}
          </p>
        </div>
      </Modal>
      <div className="testimonial-card">
        <div style={{ height: "60px" }}></div>

        <div className="testimonial-text">
          <h3>{name}</h3>
          <p>{description}</p>
        </div>
        <ul className="testimonial-rating">
          {contact && (
            <div
              style={{
                margin: "50px 0 0 0",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <p
                style={{
                  color: "#fff",
                  width: "fit-content",
                  cursor: "pointer",
                  padding: "6px 18px",
                  borderRadius: "10px",
                  background: "#583FBC",
                }}
                onClick={() => {
                  window.location.pathname = "/contact";
                }}
              >
                {i18n.language == "uz"
                  ? "Konsultatsiya olish"
                  : " Получить консультацию"}
              </p>
            </div>
          )}
          {data ? (
            <div
              style={{
                margin: "50px 0 0 0",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <p
                style={{
                  color: "#fff",
                  width: "fit-content",
                  cursor: "pointer",
                  padding: "6px 18px",
                  borderRadius: "10px",
                  background: "#583FBC",
                }}
                onClick={showModal}
              >
                {i18n.language == "uz" ? "Batafsil" : " Подробное"}
              </p>
            </div>
          ) : (
            ratings?.map((rating) => <li>{rating}</li>)
          )}
        </ul>
      </div>
    </div>
  );
};

export default TestimoniCard;
