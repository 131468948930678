import styled, { keyframes } from "styled-components";
const pulse = keyframes`
 0% {
    box-shadow: 0 0 0 0 rgba(219, 239, 250, 0.7);
  }

  70% {
    box-shadow: 0 0 0 20px rgba(219, 239, 250, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(219, 239, 250, 0);
  }
`;
export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 50px;
  padding: 120px 15px 15px 15px;
  h1 {
    font-size: 45px;
    color: #547b6c;
    font-weight: bold;
    max-width: 800px;
    margin: 0 0 50px 0;
    text-align: center;
    @media (max-width: 700px) {
      font-size: 32px;
    }
  }
`;
Wrapper.Border = styled.div`
  padding: 40px;
  width: fit-content;
  border: 3px solid #dbeffa;
  position: relative;
  border-radius: 10px;
  animation: ${pulse} 1s infinite;
`;
Wrapper.Container = styled.div`
  width: 100%;
  max-width: 800px;
  height: 52px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  background-color: #fff;
  border: 1px solid #ccc;
`;
Wrapper.Discount = styled.div`
  width: 150px;
  padding: 8px 18px;
  background-color: #583fbc;
  display: flex;
  align-items: center;
  color: #fff;
  border-radius: 25px;
  flex-direction: column;
  font-size: 25px;
  text-align: center;
  font-weight: 600;
  position: absolute;
  top: 0px;
  right: -50px;
  color: #fff;
  transform: rotate(45deg);
`;
