import "./App.scss";
import Home from "./pages/Home";
import { Routes, Route } from "react-router-dom";
import About from "./pages/About";
import Price from "./pages/price";
import BlogsPage from "./pages/BlogsPage";
import SingleBlog from "./pages/SingleBlog/SingleBlog";
import Contactus from "./pages/Contact/Contactus";
import Doctors from "./pages/doctors";
import Send from "./pages/send";
import Result from "./pages/result";
import Login from "./pages/AdminLogin";
import Admin from "./pages/Admin";
function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/send" element={<Send />} />
      <Route path="/about" element={<About />} />
      <Route path="/price" element={<Price />} />
      <Route path="/doctors" element={<Doctors />} />
      <Route path="/blogs" element={<BlogsPage />} />
      <Route path="/blog/:url" element={<SingleBlog />} />
      <Route path="/contact" element={<Contactus />} />
      <Route path="/result" element={<Result />} />
      <Route path="/login" element={<Login />} />
      <Route path="/admin" element={<Admin />} />
      <Route path="*" element={<h1>Not found</h1>} />
    </Routes>
  );
}

export default App;
