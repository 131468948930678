import imgOne from "../../assets/blogs/1.png";
import imgTwo from "../../assets/blogs/2.png";
import imgThree from "../../assets/blogs/3.png";

const BlogData = [
  {
    img: "https://static.tildacdn.com/tild6562-6636-4432-b136-313664643331/Group_9.jpg",
    url: "new-technology-make-for-dental-operation",
    title: "Сергеева (Журбенко) Виктория Владимировна",
    description: "Главный врач, врач-невролог",
    category: "Стаж 14 лет",
  },
  {
    img: "https://static.tildacdn.com/tild6562-6636-4432-b136-313664643331/Group_9.jpg",
    url: "regular-dental-care-make-your-smile-brighter",
    title: "Сергеева (Журбенко) Виктория Владимировна",
    description: "Главный врач, врач-невролог",
    category: "Стаж 14 лет",
  },
  {
    img: "https://static.tildacdn.com/tild6562-6636-4432-b136-313664643331/Group_9.jpg",
    url: "dental-hygiene-for-all-age-to-make-smile",
    title: "Сергеева (Журбенко) Виктория Владимировна",
    description: "Главный врач, врач-невролог",
    category: "Стаж 14 лет",
  },
];

export default BlogData;
