import React, { useState } from "react";
import "./Emergency.scss";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import EmergencyImg from "../../assets/humans.png";
import { Wrapper } from "./style";
import { useTranslation } from "react-i18next";
import Grija from "../../assets/n1.png";
import Grija1 from "../../assets/n2.png";

const Emergency = () => {
  const [active, setActive] = useState(0);
  const { i18n } = useTranslation();

  const items = [
    {
      contex: (
        <Wrapper.Flex>
          <Wrapper.ImgBox>
            <Wrapper.Img src={Grija} />
            <Wrapper.Img src={Grija1} />
          </Wrapper.ImgBox>

          <div>
            <h3>
              {i18n.language == "uz"
                ? "Bemor: Umurtqaning L5-S1 sohasida 11.3 mm grija"
                : "Пациент: Межпозвоночная грыжа поясничного отдела на уровне L5-S1 11.3 мм"}
            </h3>
            <p style={{ color: "red", marginTop: "5px" }}>
              {i18n.language == "uz" ? "Davolanishdan oldin:" : "До лечения:"}
            </p>
            <p>
              {i18n.language == "uz"
                ? "- Belni tik tutaolmaslik"
                : "- Невозможность выпрямить осанку и его тугоподвижность"}
            </p>
            <p>
              {i18n.language == "uz"
                ? "- O'tirganda va yurganda kuchli og'riqlar"
                : "- Сильные боли в сидячем положении и ходьбе"}
            </p>
            <p>
              {i18n.language == "uz"
                ? "- Oyoq harakatini cheklanishi"
                : "- Ограничение движение ног"}
            </p>
            <p style={{ color: "#57926d", marginTop: "5px" }}>
              {i18n.language == "uz"
                ? "1 kursdan keyin:"
                : "После 1 курса лечения:"}
            </p>
            <p>
              {i18n.language == "uz"
                ? "- O'tirgan paytdaki og'riqlar yuqoldi"
                : "- Прошли боли в сидячем положении"}
            </p>
            <p>
              {i18n.language == "uz"
                ? "- Bel tiklandi"
                : "- Пациент ходил уже с ровной осанкой"}
            </p>

            <p>
              {" "}
              {i18n.language == "uz"
                ? "- Og'riqlar sezirarli darajada kamaydi"
                : "- Боли значительно уменьшились"}
            </p>
            <p style={{ color: "#57926d", marginTop: "5px" }}>
              {i18n.language == "uz"
                ? "3 kursdan keyin:"
                : "После 3 курса лечения:"}
            </p>
            <p>
              {i18n.language == "uz"
                ? "- Grija surildi"
                : "- Грыжа рассосалась"}
            </p>
            <p>
              {i18n.language == "uz"
                ? "- Og'riqlar butkul yuqoldi"
                : "- Боли исчезли полностью"}
            </p>
            <p>
              {i18n.language == "uz"
                ? "- Bemor kundalik og'riqlarsiz xayotga qaytdi 😊"
                : "- Пациент вернулся к прежней жизни без боли😊"}
            </p>
          </div>
        </Wrapper.Flex>
      ),
    },
    {
      contex: (
        <Wrapper.Flex>
          <div style={{ width: "100%", height: "350px" }}>
            <iframe
              width="100%"
              height="350"
              style={{ borderRadius: "10px" }}
              src="https://www.youtube.com/embed/a7wklUthF5U"
              title="Бел грыжаси туфайли юролмай колган бемор"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </div>
          <div>
            <h3>
              {i18n.language == "uz"
                ? "Bemor: Umurtqaning L4-L5 sohasida 6 mm grija"
                : "Пациент: 6 мм серого цвета в области L4-L5 позвоночника."}
            </h3>
            <p style={{ color: "red", marginTop: "5px" }}>
              {i18n.language == "uz" ? "Davolanishdan oldin:" : "До лечения:"}
            </p>
            <p>
              {i18n.language == "uz"
                ? "- Oyoqdagi kuchli og'riqlar"
                : "- Сильная боль в ноге"}
            </p>
            <p>
              {i18n.language == "uz"
                ? "- Belni tik tutaolmaslik"
                : "- Невозможность держать спину прямо"}
            </p>
            <p>
              {i18n.language == "uz"
                ? "- Yurganda kuchli bel og'riqlari"
                : "- Сильная боль в спине при ходьбе"}
            </p>
            <p style={{ color: "#57926d", marginTop: "5px" }}>
              {i18n.language == "uz"
                ? "1 kursdan keyin:"
                : "После 1 курса лечения:"}
            </p>
            <p>
              {i18n.language == "uz"
                ? "- Og'riqlar 70 - 90% kamaydi"
                : "- Боль уменьшилась на 70-90%"}
            </p>
            <p>
              {i18n.language == "uz"
                ? "- Oyoq xarakati tiklandi"
                : "- Движение ног восстановлено."}
            </p>

            <p>
              {i18n.language == "uz"
                ? "- Bemorda operatsiyaga extiyoj qolmadi 😊"
                : "- Пациенту не потребовалось хирургическое вмешательство 😊"}
            </p>
          </div>
        </Wrapper.Flex>
      ),
    },
    {
      contex: (
        <Wrapper.Flex>
          <div style={{ width: "100%", height: "350px" }}>
            <iframe
              width="100%"
              height="350"
              style={{ borderRadius: "10px" }}
              src="https://www.youtube.com/embed/-Q9pZ7kUhZo"
              title="1 кисм. Буйин грыжаси билан мурожаат килган бемор.Мурожаат учун : 980777287 ; 980777127"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </div>
          <div>
            <h3>
              {i18n.language == "uz"
                ? "Bemor: Bo'ying grijasi bilan murojat qildi"
                : "Пациент: Он жаловался на боль в шее."}
            </h3>
            <p style={{ color: "red", marginTop: "5px" }}>
              {i18n.language == "uz" ? "Davolanishdan oldin:" : "До лечения:"}
            </p>
            <p>
              {i18n.language == "uz"
                ? "- Kuchli bosh og'riqlar"
                : "- Сильные головные боли"}
            </p>
            <p>
              {i18n.language == "uz"
                ? "- Qon bosimining o'ynashi"
                : "- Игра на кровяном давлении"}
            </p>
            <p>
              {i18n.language == "uz"
                ? "- Qo'l harakatini cheklanishi va kuchli og'riqlar"
                : "- Ограничение передвижения пепла и могущественные огры"}
            </p>
            <p style={{ color: "#57926d", marginTop: "5px" }}>
              {i18n.language == "uz"
                ? "Davolashdan so'ng:"
                : "Прекратить лечение:"}
            </p>
            <p>
              {i18n.language == "uz"
                ? "- Qon bosimi normal holatga qaytdi"
                : "- Кровяное давление нормализовалось"}
            </p>
            <p>
              {i18n.language == "uz"
                ? "- Bosh ogriqlar g'oyib bo'ldi va uyqu tiklandi."
                : "- Головные боли исчезли, сон восстановился."}
            </p>
            <p>
              {i18n.language == "uz"
                ? "- Qo'ldagi og'riqlar 90% kamaydi"
                : "- 90% уменьшение боли в руке"}
            </p>
          </div>
        </Wrapper.Flex>
      ),
    },
  ];

  return (
    <div className="wrapper">
      <section
        className="emergency-section"
        data-aos="fade-up"
        data-aos-duration="2000"
        style={{ marginBottom: "0px" }}
      >
        <div className="container-fluid">
          <div className="row align-items-center">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                margin: "80px 0",
              }}
            >
              <SectionTitle
                title={
                  i18n.language == "uz"
                    ? "Nechta kursda grijani surilishiga erishish mumkin ?"
                    : "За сколько курсов можно добиться рассасывания грыжи?"
                }
                style={{ marginTop: "50px" }}
              />
              {/* <div className="humans">
                <div style={{ maxWidth: "200px" }}>
                  <img
                    src={EmergencyImg}
                    alt="Emergency"
                    style={{ width: "100%" }}
                  />
                </div>
                <p>100 дан 23бемор 1 та курс даволанган</p>
              </div>
              <div className="humans">
                <div style={{ maxWidth: "200px" }}>
                  <img
                    src={EmergencyImg}
                    alt="Emergency"
                    style={{ width: "100%" }}
                  />
                </div>
                <p>27 бемор 2 курсда даволанган</p>
              </div> */}

              <Wrapper.HumansWrapper>
                <Wrapper.Betwen>
                  <img
                    src={EmergencyImg}
                    alt="Emergency"
                    style={{ width: "200px" }}
                  />
                  <p style={{ margin: "15px" }}>
                    {i18n.language == "uz"
                      ? "100 dan 23 ta bemor 1 kurs davolangan"
                      : "23 пациента из 100 прошли 1 курс лечения"}
                  </p>
                </Wrapper.Betwen>
                <Wrapper.Betwen>
                  <img
                    src={EmergencyImg}
                    alt="Emergency"
                    style={{ width: "200px" }}
                  />
                  <p style={{ margin: "15px" }}>
                    {i18n.language == "uz"
                      ? "27 ta bemor 2 kurs davolangan"
                      : "27 пациентов из 100 прошли 2 курса лечения"}
                  </p>
                </Wrapper.Betwen>

                <Wrapper.Betwen>
                  <img
                    src={EmergencyImg}
                    alt="Emergency"
                    style={{ width: "200px" }}
                  />
                  <p style={{ margin: "15px" }}>
                    {i18n.language == "uz"
                      ? "40 ta bemor 3 kurs davolangan"
                      : "40 пациентов из 100  прошли 3 курса лечения"}
                  </p>
                </Wrapper.Betwen>
                <Wrapper.Betwen>
                  <img
                    src={EmergencyImg}
                    alt="Emergency"
                    style={{ width: "200px" }}
                  />
                  <p style={{ margin: "15px" }}>
                    {i18n.language == "uz"
                      ? "7 ta bemor 4 kurs davolangan"
                      : "7 пациентов из 100 прошли 4 курса лечение"}
                  </p>
                </Wrapper.Betwen>
                <Wrapper.Betwen>
                  <img
                    src={EmergencyImg}
                    alt="Emergency"
                    style={{ width: "200px" }}
                  />
                  <p style={{ margin: "15px" }}>
                    {i18n.language == "uz"
                      ? "3 ta bemor 5 kurs davolangan"
                      : "3 пациента из 100 прошли  5 курсов лечения"}
                  </p>
                </Wrapper.Betwen>
              </Wrapper.HumansWrapper>
              {/* <div>
                <p>27 бемор 2 курсда даволанган</p>
                <p>40 та бемор 3 курс даволанган</p>
                <p>7 та бемор 4 курс даволанган </p>
                <p>3 бемор 5 курс даволанган</p>
              </div> */}
            </div>
          </div>
        </div>
      </section>

      <Wrapper.Center style={{ margin: "120px 0" }}>
        <Wrapper.Card2>
          <Wrapper.CardTitle2>
            {i18n.language == "uz"
              ? "Qachon natija buladi, og'riqlar necha kunda qoladi ?"
              : "Когда наступит улучшение и сколько дней будут длиться боли?"}
          </Wrapper.CardTitle2>
          <Wrapper.CardDesc>
            {i18n.language == "uz"
              ? "Davolanish kursining 3-5 kunlarida bemorlar kelgan xolatiga nisbatan yaxshi tarafga ancha o'zgarish sezadilar. 80% bemorlar muolajadan 5-15 kun o'tib og'riqlar sezilarli darajada kamayib kundalik hayot tarzini davom etadilar 😉"
              : "Уже на 5-7ой день 1 курса лечение пациенты заметят значительные улучшения в лучшую сторону . Спустя 10-15 суток после окончания лечения у 70-80% наблюдается значительное уменьшение боли 😉"}
          </Wrapper.CardDesc>
        </Wrapper.Card2>
      </Wrapper.Center>

      <SectionTitle
        title={
          i18n.language == "uz" ? "Davolash natijalari" : "Результаты лечения"
        }
        style={{ margin: 0 }}
      />

      <Wrapper>
        <Wrapper.Container>
          <Wrapper.Item start onClick={() => setActive(0)} active={active == 0}>
            1
          </Wrapper.Item>
          <Wrapper.Item onClick={() => setActive(1)} active={active == 1}>
            2
          </Wrapper.Item>
          <Wrapper.Item end onClick={() => setActive(2)} active={active == 2}>
            3
          </Wrapper.Item>
        </Wrapper.Container>
      </Wrapper>
      {items[active].contex}
    </div>
  );
};

export default Emergency;
