import React, { useState, useRef } from "react";
import "./ContactForm.scss";
import { useTranslation } from "react-i18next";
import { message as AntMessage } from "antd";
import moment from "moment";
import request from "../../service";

const ContactForm = () => {
  const { i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const formRef = useRef(null);
  const date = new Date();
  const formattedDate = moment(date).format("DD MMMM YYYY HH:mm");
  const postData = async (name, number, message, find) => {
    setLoading(true);

    try {
      const response = await request.post("public/call-request", {
        data: {
          fullName: name,
          phoneNumber: number,
          message: message,
          find: find,
        },
      });
      formRef.current.reset();
      AntMessage.success("Xabar yuborildi !");
      setLoading(false);
      // sendMessageToGroup(name, number, message, find);
    } catch (error) {
      AntMessage.error("Serverda xatolik !");
      setLoading(false);
      console.error("Error posting data:", error);
    }
  };

  const sendMessageToGroup = async (name, number, message, find) => {
    setLoading(true);
    const botToken = "7213936693:AAGg0FnRchV_txq8kuurSPfZ02hvfsbDdCw";
    const chatId = "-1002364300152"; // The group chat ID where the message will be sent
    const telegramApiUrl = `https://api.telegram.org/bot${botToken}/sendMessage`;

    const fullMessage = `
    ISM FAMILIYA: ${name}
  TELEFON RAQAM: ${number}
  XABAR: ${message}
  QAYERDAN HABAR TOPILGAN: ${find}
  YUBORILGAN VAQT: ${formattedDate}
  `;

    try {
      const response = await fetch(telegramApiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          chat_id: chatId,
          text: fullMessage,
        }),
      });

      const data = await response.json();
      if (data.ok) {
        formRef.current.reset();
        AntMessage.success("Xabar yuborildi !");
        setLoading(false);
      } else {
        AntMessage.error("Serverda xatolik !");
        setLoading(false);
      }
    } catch (error) {
      console.error("Error sending message:", error);
      AntMessage.error("Serverda xatolik !");
      setLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const name = e.target.name.value;
    const number = e.target.number.value;
    const message = e.target.message.value;
    const find = e.target.find.value;

    if (!number || !name || !message || !find) {
      AntMessage.warning("Iltimos malumotlarni toldiring !");
    } else {
      postData(name, number, message, find);
    }
  };
  return (
    <form onSubmit={handleSubmit} ref={formRef}>
      <div className="row">
        <div className="col-lg-6">
          <div class="form-group">
            <label>
              {i18n.language == "uz" ? "Ism familiya" : "Имя  фамилия"}
            </label>
            <input
              name="name"
              class="form-control"
              placeholder={
                i18n.language == "uz"
                  ? "Ism familiyani kiriting..."
                  : "Пожалуйста, введите свое имя."
              }
            />
          </div>
        </div>
        <div className="col-lg-6">
          <div class="form-group">
            <label>
              {i18n.language == "uz" ? "Telefon raqam" : "Номер телефона"}
            </label>
            <input
              name="number"
              class="form-control"
              placeholder={
                i18n.language == "uz"
                  ? "Telefon raqamni kiriting..."
                  : "Введите номер телефона..."
              }
            />
          </div>
        </div>

        <div className="col-lg-12">
          <div class="form-group">
            <label for="exampleFormControlTextarea1">
              {i18n.language == "uz" ? "Xabar" : "Сообщение"}
            </label>
            <textarea
              name="message"
              class="form-control"
              placeholder={
                i18n.language == "uz"
                  ? "Xabarni kiriting..."
                  : "Введите сообщение..."
              }
              rows="3"
            ></textarea>
          </div>
          <div class="form-group">
            <label>
              {i18n.language == "uz"
                ? "Bizni qanday topdingiz ?"
                : "Как вы узнали о нас ?"}
            </label>
            <select className="form-control" id="exampleSelect" name="find">
              <option value="">
                {i18n.language == "uz"
                  ? "Quyidagilarni tanlang"
                  : "Выберите следующее"}
              </option>
              <option value="Telegram">Telegram</option>
              <option value="Instagram">Instagram</option>
              <option value="Facebook">Facebook</option>
              <option value="Tanishimdan">
                {i18n.language == "uz" ? "Tanishimdan" : " От знакомого"}
              </option>
            </select>
          </div>
        </div>

        <div className="col-lg-6">
          <button type="submit" class="btn appointment-btn">
            {loading
              ? "Loading ..."
              : i18n.language == "uz"
              ? "Yuborish"
              : "Отправка"}
          </button>
        </div>
      </div>
    </form>
  );
};

export default ContactForm;
