import React from "react";
import "./Appointment.scss";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import { AiFillHome } from "react-icons/ai";
import ContactForm from "../../components/ContactForm/ContactForm";
import { useTranslation } from "react-i18next";

const Appointment = () => {
  const mapLink =
    "https://maps.google.com/maps?q=Toshkent%20viloyati,Qibray%20tumani,Qibray%20Shaharchasi%20,%20A.Navoiy%20ko%E2%80%99chasi,19%20-%20uy&amp;t=&amp;z=11&amp;ie=UTF8&amp;iwloc=&amp;output=embed";
  const { i18n } = useTranslation();

  return (
    <section
      className="appointment-section pb-70"
      data-aos="fade-up"
      data-aos-duration="2000"
      style={{ margin: "90px 0", paddingTop: "10px" }}
    >
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-7 col-md-6">
            <div className="appointment-form-area">
              <SectionTitle
                subTitle={
                  i18n.language == "uz"
                    ? "BIZ BILAN ALOQA"
                    : " СВЯЗАТЬСЯ С НАМИ"
                }
                title={
                  i18n.language == "uz"
                    ? "Sizga yordam berishdan xursandmiz"
                    : " Мы рады помочь вам"
                }
                description={
                  i18n.language == "uz"
                    ? "Xabaringizni qoldiring o'zimiz qaytib aloqaga chiqamiz"
                    : "Оставьте свое сообщение и мы свяжемся с вами"
                }
              />

              <ContactForm />
            </div>
          </div>

          <div className="col-lg-5 col-md-6">
            <div className="google-map">
              {/* <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d544.886773695482!2d69.39018068940842!3d41.36111893421129!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38aef7005a384099%3A0x1e87ad5f6b6fcf81!2sUy!5e0!3m2!1suz!2sus!4v1726164576040!5m2!1suz!2sus"></iframe> */}
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2993.5497568052488!2d69.44954659999999!3d41.383865899999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38aef1006a54fdd9%3A0xfd33a06f8e32a2f9!2sAvicenna%20pupil!5e0!3m2!1sen!2s!4v1726770416286!5m2!1sen!2s"></iframe>
              <div className="location-name">
                <AiFillHome />
                <p>
                  {i18n.language == "uz"
                    ? "Qibray tumani , Ofarin 4 . Orientir Qibray rovd"
                    : "Кибрайский район, Офарин 4. Ориентир Кибрай ровд"}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Appointment;
