import styled from "styled-components";
export const WrapperPrice = styled.div`
  width: 100%;
  max-width: 1290px;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
`;

WrapperPrice.Flex = styled.div`
  width: 100%;
  display: flex;
  gap: 34px;
  max-width: 1292px;
  margin-top: 50px;
  padding: 0 10px;
  @media (max-width: 575px) {
    flex-direction: column;
  }
  p {
    font-size: 15px;
  }
`;

WrapperPrice.Row = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #ccc;
  padding: 0 15px;
  background: ${({ bg }) => bg && bg};
  p {
    margin: 0;
  }
`;
WrapperPrice.Info = styled.div`
  width: 100%;
  min-height: 240px;
  border-radius: 25px;
  background-color: #f5f7fa;
  background-position: center center;
  border-color: transparent;
  border-style: solid;
  padding: 30px;
  display: flex;
  flex-direction: column;
  span {
    color: #57826d;
    cursor: pointer;
  }
  p {
    margin: 20px 0 0 0;
  }
  h5 {
    font-weight: bold;
    color: #57826d;
  }
`;
WrapperPrice.Flex = styled.div`
  width: 100%;
  display: flex;
  align-items: start;
  gap: 24px;
  margin: 0 0 40px 0;
  @media (max-width: 875px) {
    flex-direction: column;
  }
`;
WrapperPrice.Center = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0 10px;
`;
WrapperPrice.ImgBox = styled.div`
  display: flex;
  height: 350px;
  border-radius: 10px;
  @media (max-width: 575px) {
    height: 250px;
    margin: 10px 0;
  }
`;
WrapperPrice.Img = styled.img`
  width: 50%;
  height: 350px;
  @media (max-width: 575px) {
    height: 250px;
  }
`;
WrapperPrice.Card = styled.div`
  max-width: 400px;
  width: 100%;
  padding: 50px;
  background-color: #583fbc;
  display: flex;
  align-items: center;
  color: #fff;
  border-radius: 10px;
  flex-direction: column;
`;
WrapperPrice.CardTitle = styled.div`
  font-size: 80px;
  font-weight: 900;
  text-align: center;
  color: #fff;
`;
WrapperPrice.CardDesc = styled.div`
  font-size: 30px;
  text-align: center;
  font-weight: 600;
  color: #fff;
`;
WrapperPrice.Card2 = styled.div`
  max-width: 800px;
  width: 100%;
  padding: 50px;
  background-color: #583fbc;
  display: flex;
  align-items: center;
  color: #fff;
  border-radius: 10px;
  flex-direction: column;
`;
WrapperPrice.CardTitle2 = styled.div`
  font-size: 40px;
  font-weight: 900;
  text-align: center;
  color: #fff;
  margin: 0 0 10px 0;
`;
