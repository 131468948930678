import React, { useState } from "react";
import { Layout, Menu, Button } from "antd";
import {
  UserOutlined,
  TagsOutlined,
  LogoutOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
} from "@ant-design/icons";
import UserFeedback from "./feedback";
import DiscountSection from "./feedback_discount";

const { Header, Content, Sider } = Layout;

const AdminPanel = () => {
  const [collapsed, setCollapsed] = useState(false); // State for collapsed sidebar
  const [selectedSection, setSelectedSection] = useState("feedback");

  const handleMenuClick = ({ key }) => {
    setSelectedSection(key);
  };

  const handleExit = () => {
    // Logic for exit (e.g., log out, redirect)
    window.location.pathname = "/";
  };

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      {/* Sidebar with Menu */}
      <Sider
        collapsed={collapsed}
        onCollapse={setCollapsed}
        breakpoint="md"
        collapsedWidth={0}
        theme="dark"
        trigger={null}
        style={{ background: "linear-gradient(135deg, #4b79a1, #283e51)" }}
      >
        <div
          style={{
            padding: "16px",
            textAlign: "center",
            color: "#fff",
            fontSize: "24px",
            fontWeight: "bold",
          }}
        >
          Admin Panel
        </div>
        <Menu
          theme="dark"
          defaultSelectedKeys={["feedback"]}
          mode="inline"
          onClick={handleMenuClick}
          style={{ background: "linear-gradient(135deg, #4b79a1, #283e51)" }}
        >
          <Menu.Item key="feedback" icon={<UserOutlined />}>
            Murojatlar
          </Menu.Item>
          <Menu.Item key="discount" icon={<TagsOutlined />}>
            Aksiya
          </Menu.Item>
        </Menu>
        {/* Exit button at the bottom */}
        <div style={{ padding: "16px" }}>
          <Button
            type="primary"
            icon={<LogoutOutlined />}
            style={{
              width: "100%",
              background: "#ff4d4f",
              borderColor: "#ff4d4f",
            }}
            onClick={handleExit}
          >
            Exit
          </Button>
        </div>
      </Sider>

      {/* Main Content Area */}
      <Layout>
        <Header style={{ background: "#fff", padding: 0 }}>
          {/* Menu toggle button */}
          <Button
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={toggleCollapse}
            style={{ margin: "16px" }}
          />
        </Header>
        <Content style={{ margin: "16px" }}>
          {selectedSection === "feedback" && <UserFeedback />}
          {selectedSection === "discount" && <DiscountSection />}
        </Content>
      </Layout>
    </Layout>
  );
};

export default AdminPanel;
