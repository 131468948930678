import React from "react";
import { Link } from "react-router-dom";
import "./BlogCard.scss";

const BlogCard = ({ blog }) => {
  const { title, img, description, category, url } = blog;

  return (
    <div className="col-lg-4 col-md-6 col-sm-6">
      <div className="blog-card">
        <div className="blog-img">
          <img src={img} alt="blog" style={{ borderRadius: "20px" }} />
        </div>
        <div className="blog-text">
          <span>
            <Link to="/">{category}</Link>
          </span>
          <h3>
            <Link to={`/blog/${url}`}>{title}</Link>
          </h3>
          <div
            style={{
              margin: "10px 0 0 0",
              color: "#fff",
              width: "fit-content",
              cursor: "pointer",
              padding: "6px 18px",
              borderRadius: "10px",
              background: "#583FBC",
            }}
          >
            Записаться
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogCard;
