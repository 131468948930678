import React, { useEffect } from "react";
import Navbar from "../components/Navbar/Navbar";
import AboutBanner from "../sections/AboutBanner/AboutBanner";
import Faq from "../sections/Faq/Faq";
import Priority from "../sections/Priority/Priority";
import Safety from "../sections/Safety/Safety";
import Team from "../sections/Team/Team";
import BlogsPage from "./BlogsPage";
import Footer from "../sections/Footer/Footer";
import SectionTitle from "../components/SectionTitle/SectionTitle";
import Doctors from "../sections/Blogs/BlogData";
import BlogCard from "../components/BlogCard/BlogCard";
import { WrapperPrice } from "./style";
import { useTranslation } from "react-i18next";

const About = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    window.scrollTo({ top: "0px" });
  }, []);
  return (
    <>
      <Navbar />
      <div className="wrapper" style={{ marginBottom: "50px" }}>
        <section>
          <SectionTitle
            title={
              i18n.language == "uz"
                ? "Grija rezorbsiyasi"
                : "Резорбция грыжи диска"
            }
          />
          <h1 style={{ marginBottom: "50px" }}>
            {i18n.language == "uz"
              ? "Klinika xizmatlari uchun narxlar"
              : " Цены на услуги клиники"}
          </h1>
        </section>
        <div className="container">
          <WrapperPrice>
            <WrapperPrice.Row>
              <p style={{ fontWeight: "bold" }}>
                {i18n.language == "uz"
                  ? "Umurtqa grijalarini operatsiyasiz davolash- Ambulator tarzda"
                  : "Безоперационнон лечение грыжи диска - Амбулаторно"}
              </p>
              <p style={{ fontWeight: "bold" }}>
                {i18n.language == "uz" ? "Narxi, so'm" : "Цена ,SUM"}
              </p>
            </WrapperPrice.Row>

            <WrapperPrice.Row bg={"#F5F7FA"}>
              <p>
                {i18n.language == "uz"
                  ? "1 seans kompleks davolash (1,5-2 soat)"
                  : "1 сеанс комплексного лечения (1,5-2 часа)"}
              </p>
              <p> 300.000-450.000</p>
            </WrapperPrice.Row>

            <WrapperPrice.Row bg={"#F5F7FA"}>
              <p>
                {i18n.language == "uz"
                  ? "1 kurs davolanish ambulyator tarzda(7 - 12kg)"
                  : "1 курс лечения амбулаторно (7-12 дней)"}
              </p>
              <p> 2.6 млн - 4,8 млн</p>
            </WrapperPrice.Row>

            <h3 style={{ margin: "20px 0" }}>
              {i18n.language == "uz"
                ? "Muolaja nimalardan iborat ?"
                : "Каково лечение ?"}
            </h3>
            <p>{i18n.language == "uz" ? " -иглотерапия" : " -иглотерапия"}</p>
            <p>
              {i18n.language == "uz"
                ? " -elektroakupunktura"
                : " -электроакупунктура"}
            </p>
            <p>
              {i18n.language == "uz"
                ? " -zarb- to'lqinli terapiya (uvt)"
                : " -Ударно-волновая терапия(УВТ)"}
            </p>
            <p>{i18n.language == "uz" ? " -Tesla magnit" : " -Тесла Магнит"}</p>
            <p>
              {i18n.language == "uz"
                ? " -Vlok terapiya (bemorni xolatidan kelib chiqib)"
                : " -влок-терапия (в зависимости от состояния пациента)"}
            </p>
            <p>
              {i18n.language == "uz"
                ? " -nuqtali massaj (maxsus apparatda)"
                : "-точечный массаж (на специальном оборудовании)"}
            </p>
            <p>
              {i18n.language == "uz"
                ? " -infraqizil nurlanish"
                : "-инфракрасное излучение"}
            </p>

            <h3 style={{ margin: "20px 0" }}>
              {i18n.language == "uz"
                ? "1 kurs statsionar davolanish(7 - 12 kun):"
                : "1 курс стационарного лечения (7-12 дней):"}
            </h3>
            <p>
              {i18n.language == "uz"
                ? "-660.000 lyuks (1 kuniga)"
                : "-660 000 люкс (в день)"}
            </p>
            <p>
              {i18n.language == "uz"
                ? "-660.000 polyuks (1 kuniga)"
                : "-660 000 полулюкс (в день)"}
            </p>
            <p>
              {i18n.language == "uz"
                ? "-660.000 standart (1 kuniga)"
                : "-550 000 стандарт (в день)"}
            </p>

            <h3 style={{ margin: "20px 0" }}>
              {i18n.language == "uz"
                ? "Bu narxga quyidagilar kiradi:"
                : "В эту цену включено следующее:"}
            </h3>
            <p>{i18n.language == "uz" ? "-Igloterapiya" : "-Иглотерапия"}</p>
            <p>
              {i18n.language == "uz"
                ? "-Elektroakupunktura"
                : "-электроакупунктура"}
            </p>
            <p>
              {i18n.language == "uz"
                ? " -Infraqizil nurlanish"
                : "-Инфракрасное излучение"}
            </p>
            <p>
              {i18n.language == "uz"
                ? " -VLOK terapiya (bemorni xolatidan kelib chiqib)"
                : " -ВЛОК-терапия (в зависимости от состояния пациента)"}
            </p>
            <p>
              {i18n.language == "uz"
                ? " -Zarb- to'lqinli terapiya (uvt)"
                : " -Ударно-волновая терапия (УВТ)"}
            </p>
            <p>
              {i18n.language == "uz"
                ? " -Kapping terapiya"
                : " -Каппинг терапия"}
            </p>
            <p>{i18n.language == "uz" ? " -Tesla magnit" : " -Тесла Магнит"}</p>

            <p>
              {i18n.language == "uz"
                ? " -Nuqtali massaj (maxsus apparatda)"
                : "-Точечный массаж (на специальном оборудовании)"}
            </p>
            <p>{i18n.language == "uz" ? " -ozonterapiya" : "-озонотерапия"}</p>
            <p>
              {i18n.language == "uz"
                ? " -Neyrostimulyatsiya"
                : "-Транслингвальная нейростимуляция"}
            </p>
            <p>
              {i18n.language == "uz"
                ? " -Tor mutahasislar ko'rigi"
                : "-Узкая область специалистов"}
            </p>
            <p>
              {i18n.language == "uz"
                ? " -Fizioterapiya"
                : "-Физиотерапия(узт,электрофарез,)"}
            </p>
            <p>{i18n.language == "uz" ? " -Fitoterapiya" : "-Фитотерапия"}</p>
            <p>
              {i18n.language == "uz" ? " -Massaj (1zona)" : "-Массаж (1зона)"}
            </p>
            <p>
              {i18n.language == "uz" ? " -UZI (1 organ)" : "-Узи ( 1 орган)"}
            </p>
            <p>{i18n.language == "uz" ? " -EKG" : "-ЭКГ"} </p>
            <p>
              {i18n.language == "uz"
                ? " -Asosiy labarator tekshiruvlar"
                : "-Основные лабораторные анализы"}{" "}
            </p>
            <p>
              {i18n.language == "uz" ? " -4 maxal ovqat" : "-4 местных блюда"}{" "}
            </p>
          </WrapperPrice>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default About;
