import React, { useState, useEffect } from "react";
import "./Testimonial.scss";
import ContactForm from "../../components/ContactForm/Reklama";
import { useTranslation } from "react-i18next";
import { Wrapper } from "./style";
const Reklama = () => {
  const { i18n } = useTranslation();
  const initialTime = 3600;
  const [timeLeft, setTimeLeft] = useState(
    parseInt(localStorage.getItem("discountTimer")) || initialTime
  );

  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setInterval(() => {
        setTimeLeft((prevTime) => {
          const updatedTime = prevTime - 1;
          localStorage.setItem("discountTimer", updatedTime);
          return updatedTime;
        });
      }, 1000);

      return () => clearInterval(timer);
    } else {
      // Clear localStorage when time is up to prevent a restart
      localStorage.removeItem("discountTimer");
    }
  }, [timeLeft]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };
  return (
    <Wrapper>
      <Wrapper.Border>
        {/* <Wrapper.Discount>{formatTime(timeLeft)}</Wrapper.Discount> */}
        <h1>
          {i18n.language == "uz"
            ? "Raqamingizni qoldiring va birinchi bepul muolajaga ega bo'ling"
            : "Оставьте свой номер и получите 1 сеанс лечение бесплатно"}
        </h1>
        <ContactForm />
      </Wrapper.Border>
    </Wrapper>
  );
};

export default Reklama;
