import axios from "axios";
const request = axios.create({
  baseURL: "https://alchem.mytestlab.site/api/",
  headers: {
    common: {
      Authorization: localStorage.getItem("token")
        ? "Basic " + localStorage.getItem("token")
        : null,
    },
  },
  timeout: 30000,
});

request.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token")
      ? "Basic " + localStorage.getItem("token")
      : null;
    config.headers["Authorization"] = `${token}`;
    return config;
  },
  (error) => {
    if (error.response?.status === 401) {
      localStorage.clear();
      window.location.pathname = "/register";
    }
    return Promise.reject(error);
  }
);

request.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    const location = window.location.pathname;

    if (err?.response?.status === 401 || err?.response?.status === 403) {
      if (location !== "/login") {
        localStorage.clear();
        window.location.pathname = "/login";
      } else {
        return Promise.reject(err);
      }
    } else {
      return Promise.reject(err);
    }
  }
);

export default request;
