import React from "react";
import { Form, Input, Button, message } from "antd";
import "./style.css"; // Create this file for custom styles
import request from "../../service";
import { useNavigate } from "react-router-dom";
const SignInPage = () => {
  const navigate = useNavigate();
  const onFinish = (values) => {
    console.log(values, "values");
    postData(values);
  };
  const postData = async (values) => {
    if (values.login === "iticadmin" && values.password === "iticadmin") {
      localStorage.setItem("token", "aXRpY2FkbWluOml0aWNhZG1pbg==");
      message.success("Xush kelibsiz");
      navigate("/admin");
    } else {
      message.warning("Login yoki parol noto'g'ri");
    }
  };
  return (
    <div className="signin-page">
      <Form
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        className="signin-form"
      >
        <Form.Item
          label="Login"
          name="login"
          rules={[{ required: true, message: "Loginni kiriting" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Parol"
          name="password"
          rules={[{ required: true, message: "Parolni kiriting" }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            type="primary"
            htmlType="submit"
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              margin: "10px 0",
            }}
          >
            Kirish
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            onClick={() => navigate("/")}
            style={{
              backgroundColor: "red",
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            Bosh sahifaga qaytish
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default SignInPage;
