import React from "react";
import "./Emergency.scss";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import { useTranslation } from "react-i18next";
import isbotlangan from "../../assets/isbotlangan.png";
import natija from "../../assets/natija.png";
import eng from "../../assets/eng.png";
import { Wrapper } from "./style";
const Emergency = () => {
  const { i18n } = useTranslation();

  return (
    <div className="wrapper">
      <section
        className="emergency-section"
        data-aos="fade-up"
        data-aos-duration="2000"
        style={{ margin: "50px 50px 0px 50px" }}
      >
        <div className="container-fluid">
          {/* <div className="flex-wrapper" style={{ margin: "60px 0" }}>
            <Wrapper.Wrap>
              <Wrapper.Title>2500+</Wrapper.Title>
              <p
                style={{
                  fontSize: "16px",
                  color: "#547B6C",
                }}
              >
                {i18n.language == "uz"
                  ? "Bizda davolanishdan mamnun bemorlar"
                  : "Пациенты, довольные нашим лечением"}
              </p>
            </Wrapper.Wrap>

            <Wrapper.Wrap>
              <Wrapper.Title>90%</Wrapper.Title>
              <p
                style={{
                  fontSize: "16px",
                  color: "#547B6C",
                }}
              >
                {i18n.language == "uz" ? "Natija" : "Результат"}
              </p>
            </Wrapper.Wrap>

            <Wrapper.Wrap>
              <Wrapper.Title>3</Wrapper.Title>
              <p
                style={{
                  fontSize: "16px",
                  color: "#547B6C",
                }}
              >
                {i18n.language == "uz"
                  ? "ta davlatda Rossiya , Hindiston , Xitoyda tajriba orttirilgan"
                  : "Опыт накоплен в России, Индии и Китае"}
              </p>
            </Wrapper.Wrap>
          </div> */}

          <div className="row align-items-center">
            <div className="col-lg-6 col-md-6">
              <img
                src={"https://kurgan.vitom.pro/upload/katalog/nevrolog6.jpg"}
                alt="Emergency"
              />
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="emergency-text">
                <SectionTitle
                  title={
                    i18n.language == "uz"
                      ? "Ogir nevrologik kasalliklarni davolaymiz:"
                      : "Немедикоментозное и безоперационное лечение неврологических заболевании :"
                  }
                />

                <div>
                  <p style={{ color: "#547B6C", fontWeight: "bold" }}>
                    {i18n.language == "uz"
                      ? "-Umurtqa grijalari"
                      : "-Межпозвоночные грыжи"}
                  </p>
                  <p style={{ color: "#547B6C", fontWeight: "bold" }}>
                    {i18n.language == "uz" ? "-Migren " : " -Мигрень"}
                  </p>
                  {/* <p style={{ color: "#547B6C", fontWeight: "bold" }}>
                    {i18n.language == "uz"
                      ? "-Bog`im og`riqlari"
                      : "-Боль в суставах"}
                  </p> */}
                  <p style={{ color: "#547B6C", fontWeight: "bold" }}>
                    {i18n.language == "uz"
                      ? "-Insultdan keyingi asoratlar"
                      : " -После инсультные осложнения"}
                  </p>
                  <p style={{ color: "#547B6C", fontWeight: "bold" }}>
                    {i18n.language == "uz"
                      ? "-Yuz nevriti "
                      : " -Лицевой неврит"}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Emergency;
