import React, { useRef } from "react";
import { Container, Wrapper } from "./styled";
import Card from "./card";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import Slider from "react-slick";
import TestimoniCard from "./TestimoniCard";
import { AiFillStar } from "react-icons/ai";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import Igno from "./../../assets/igno.jpg";
import Kapun from "./../../assets/elektrokapun.jpg";
import Tesla from "./../../assets/tesla.webp";
import Uvt from "./../../assets/uvt-mini.png";
import Infra from "./../../assets/infra.jpg";
import Bemor1 from "./../../assets/bemor1.jpg";
import Bemor2 from "./../../assets/y.jpg";
import Sur from "./../../assets/sur.jpg";
import Bemor3 from "./../../assets/b.jpg";
const Method = () => {
  const { i18n } = useTranslation();

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const testimonails = [
    {
      name: `Обратилась в клинику с протрузиями двух
позвонков, принимала врач Ильясова
Ирина Михайловна, очень приятный
специалист, ...`,
      img: Bemor1,
    },
    {
      name: `Обратилась в клинику с протрузиями двух
позвонков, принимала врач Ильясова
Ирина Михайловна, очень приятный
специалист, ...`,
      img: Bemor2,
    },
    {
      name: `Обратилась в клинику с протрузиями двух
позвонков, принимала врач Ильясова
Ирина Михайловна, очень приятный
специалист, ...`,
      img: Sur,
    },
    {
      name: `Обратилась в клинику с протрузиями двух
позвонков, принимала врач Ильясова
Ирина Михайловна, очень приятный
специалист, ...`,
      img: Bemor3,
    },
  ];
  const sliderRef = useRef(null);
  const nextSlide = () => {
    sliderRef.current.slickNext();
  };

  const prevSlide = () => {
    sliderRef.current.slickPrev();
  };

  return (
    <Container>
      <SectionTitle
        title={i18n.language == "uz" ? "Davolash usullari" : "Методы лечения"}
      />
      <Wrapper>
        <Card
          title={
            i18n.language == "uz" ? "Igna terapiya" : "Иглорефлексотерапия"
          }
          desc={
            i18n.language == "uz"
              ? `Иглорефлексотерапия- бу бизнинг Энг асосий даволаш методларимиздан
Бугунги кунда иглотерапия : АКШ , Европа давлатлари ,Жанубий Корея ва шунга охшаш ривожланган давлатларда   кўплаб илмий ишлар  килинган ва уз исботини топган.
Бу муолажа нафакат умуртка грыжалари ва богим огрикларида ,балки Бош огрикларида хам юкори натижаларга эга.`
              : `Иглорефлексотерапия - один из наших основных методов лечения.До сегодняшнего дня в США , многих Европеейских странах и Южной Корее было проведено множество научных работ связанных с иглотерапией и по окончанию научных работ было доказано высокая эффективность этой процедуры.
              \n Лечение иглотерапией дает отличный результат не только при межпозвоночных грыжах, но и при головных болях, после инсультных осложнениях и др. неврологических заболеваниях.`
          }
          img={Igno}
        />
        <Card
          title={
            i18n.language == "uz" ? "Elektroapunktura" : "Электроакупунктура"
          }
          desc={
            i18n.language == "uz"
              ? "Nerv impulslarining sezgirligini oshiradi, spazmlarni yo'qotadi"
              : "Повышает проводимость нервных импульсов , снимает спазмы мыщц."
          }
          img={Kapun}
        />
        <Card
          title={i18n.language == "uz" ? "Tesla magnit" : "Тесла Магнит"}
          desc={
            i18n.language == "uz"
              ? `Тесла магнит: Юкори-интенсив магнит майдон хосил килади ва унинг ёрдамида мушакларни ва богимларни даволайди, огрикларни камайтиради, нейрорегенерацияда ёрдам беради
Муолажага курсатмалар:
-Уткир ва Сурункали Белдаги огриклар
-Мушакдаги огрикларда
-Радикулопатия( нерв буйлаб огрикларда)
-Адгезивный капсулит(Импинджмент- синдром)
-Богим огрикларида
Кимларга мумкун эмас?
 Хомиладор аёлларга
-ишланаётган зонада Темир булса,стент йоки кардиостимулятор булса.`
              : `Магнит Тесла: создает магнитное поле высокой интенсивности, которое лечит мышцы и суставы, уменьшает боли и способствует нейрорегенерации.
\nПоказание : 
-острые и хронические поясничные боли.
-боли в мышцах
-Радикулопатия( боли по ходу нерва)
-Адгезивный капсулит 
-Боли в суставах
\nКому противопоказано?
-беременным
-наличие железа в зоне работы( эндопротезы , стенты, кардиостимуляторы)
-при онкологических случаях`
          }
          img={Tesla}
        />
        <Card
          title={
            i18n.language == "uz"
              ? "Zarb to'lqinli terapiya"
              : "Ударно-волновая терапия"
          }
          desc={
            i18n.language == "uz"
              ? `-Зарб-толкинли терапия( Мушаклардаги спазмлардан халос килади, кон айланиш тизимини яхшилайди, хужайралар янгиланишини таминлайди)

Муолажага курсатмалар:
-Уткир ва Сурункали Белдаги огриклар
-Умуртка грыжаларида
-Мушакдаги огрикларда
-Радикулопатия( нерв буйлаб огрикларда)
-Адгезивный капсулит(Импинджмент- синдром)
-Богим огрикларида

Кимларга мумкун эмас?
- Хомиладор аёлларга
-ишланаётган зонада Темир булса,стент йоки кардиостимулятор булса.
-Онкологик холатларда
-Гемофилия аникланган беморларга`
              : `Ударно-волновая терапия( избавляет от мышечных спазмов, улучшает кровообращение, ускоряет регенерацию клеток)
          \nПоказание к УВТ:
-Острые и хронические боли в спине
-межпозвоночные грыжи
-Радикулопатия ( при болях по  ходу нерва)
-Адгезивный капсулит( Импинджмент синдром)
-Боли в суставах 
 \nКому противопоказано ?
- беременным
- наличие железа в зоне действие ,стента или кардиостимулятора.
-В онкологических состояниях
- Пациентам с гемофилией`
          }
          img={Uvt}
        />
        <Card
          title={
            i18n.language == "uz"
              ? "Infra-qizil nurlanish"
              : "Инфракрасное облучение "
          }
          desc={
            i18n.language == "uz"
              ? `Mushaklardagi va bog'imlardagi shamollashlarni yuqotadi`
              : `Помогает снять воспаление в мышцах и суставах`
          }
          img={Infra}
        />
      </Wrapper>

      <div className="container">
        <Wrapper style={{ padding: "0", margin: "120px 0 0 0" }}>
          <h1>
            {i18n.language == "uz"
              ? "Bemorlarimiz fikrlari"
              : "  Отзывы наших пациентов"}
          </h1>
        </Wrapper>

        <div className="testimonial-slider-wrapper">
          <Slider {...settings} ref={sliderRef} className="testimoni-slider">
            {testimonails.map((testimonail, index) => (
              <TestimoniCard key={index} testimonail={testimonail} />
            ))}
          </Slider>

          {/* Custom navigation buttons */}
          <Button
            type="primary"
            shape="circle"
            icon={<LeftOutlined />}
            onClick={prevSlide}
            className="slider-nav slider-nav-left"
          />
          <Button
            type="primary"
            shape="circle"
            icon={<RightOutlined />}
            onClick={nextSlide}
            className="slider-nav slider-nav-right"
          />
        </div>
      </div>
    </Container>
  );
};

export default Method;
