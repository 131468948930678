import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
`;
Wrapper.Container = styled.div`
  width: 100%;
  max-width: 800px;
  height: 52px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  background-color: #fff;
  border: 1px solid #ccc;
`;
Wrapper.Item = styled.div`
  cursor: pointer;
  flex: 1;
  height: 100%;
  background-color: ${({ active }) => (active ? "#dbeffa" : "#fff")};
  color: #000;
  font-weight: 500;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${({ start }) => start && "20px 0 0 20px"};
  border-radius: ${({ end }) => end && "0px 20px 20px 0px"};
  border-right: ${({ end }) => !end && "1px solid #ccc"};
`;
Wrapper.Flex = styled.div`
  width: 100%;
  display: flex;
  gap: 34px;
  max-width: 1292px;
  margin-top: 50px;
  padding: 0 10px;
  @media (max-width: 575px) {
    flex-direction: column;
  }
  p {
    font-size: 15px;
  }
`;
Wrapper.HumansWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: space-between;
  justify-content: center;
  flex-direction: column;
  max-width: 700px;
`;
Wrapper.Betwen = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 700px) {
    flex-direction: column;
  }
`;
Wrapper.Title = styled.div`
  font-size: 35px;
  font-weight: 800;
  color: rgb(84, 123, 108);
  @media (max-width: 700px) {
    font-size: 32px;
  }
`;

Wrapper.desc = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  color: #547b6c;
  @media (max-width: 700px) {
    margin: 0;
    font-size: 18px;
  }
  span {
    font-size: 35px;
    font-weight: 800;
    color: rgb(84, 123, 108);
    @media (max-width: 700px) {
      font-size: 22px;
    }
  }
`;
Wrapper.Wrap = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  width: 100%;
  justify-content: left;
  @media (max-width: 700px) {
    flex-direction: column;
    justify-content: center;
  }
`;
