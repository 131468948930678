import React from "react";
import "./Navbar.scss";
import logo from "./../../assets/logo.png";
import { Link } from "react-router-dom";
import Select from "../lang_select";
import { useTranslation } from "react-i18next";

const Navbar = () => {
  const { i18n } = useTranslation();
  const navbarItems = [
    {
      name: i18n.language == "uz" ? "Biz haqimizda" : "O Hac",
      path: "/",
    },
    {
      name: i18n.language == "uz" ? "Grija rezorbsiyasi" : "Резорбция грыжи",
      path: "/about",
    },
    // {
    //   name: "Врачи",
    //   path: "/doctors",
    // },
    {
      name: i18n.language == "uz" ? "Narxlar" : "Цены",
      path: "/price",
    },
    {
      name: i18n.language == "uz" ? "MRT yuborish" : "Отправить МРТ",
      path: "/send",
    },
    {
      name:
        i18n.language == "uz" ? "Davolash natijalari" : "Результаты лечения",
      path: "/result",
    },
    {
      name: i18n.language == "uz" ? "Aloqa" : "Контакты",
      path: "/contact",
    },
  ];

  return (
    <div className="main-nav">
      <div className="container">
        <nav className="navbar navbar-expand-lg">
          <div className="container-fluid">
            {/* Logo */}
            <Link className="navbar-brand" to="/" style={{ fontWeight: 600 }}>
              <img
                src={logo}
                alt="logo"
                style={{ width: "60px", margin: "0 10px 0 0" }}
              />
              ELDOR NEVRO
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              {/* Navbar Link */}
              <ul className="navbar-nav m-auto mb-2 mb-lg-0">
                {navbarItems.map((navSingle) => (
                  <li className="nav-item">
                    <Link className="nav-link" to={navSingle.path}>
                      {navSingle.name}
                    </Link>
                  </li>
                ))}
              </ul>
              <div className="select_lang_show">
                <Select />
              </div>
            </div>
          </div>
        </nav>
      </div>
      <div className="select_lang">
        <Select />
      </div>
    </div>
  );
};

export default Navbar;
