import React, { useEffect, useState } from "react";
import { Table } from "antd";
import request from "../../service";
import moment from "moment/moment";

const columns = [
  {
    title: "ISM",
    dataIndex: "fullName",
    key: "name",
  },
  {
    title: "TELEFON RAQAM",
    dataIndex: "phoneNumber",
    key: "number",
  },
  {
    title: "XABAR",
    dataIndex: "message",
    key: "XABAR",
  },
  {
    title: "XABAR TOPILGAN MANBASI",
    dataIndex: "find",
    key: "find",
  },
  {
    title: "YUBORILGAN VAQT",
    render: (text, record) => (
      <p type="primary">
        {moment(record.createdAt).format("DD MMM YYYY HH:mm")}
      </p>
    ),
    key: "time",
  },
];

const UserFeedback = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const getData = async () => {
    setLoading(true);
    try {
      const response = await request.get("admin/call-request/all");
      setData(response?.data?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error posting data:", error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <h2 style={{ margin: "0 0 30px 0" }}>Murojatlar</h2>
      {/* Set scroll for horizontal and vertical overflow */}
      <Table
        dataSource={data}
        columns={columns}
        loading={loading}
        scroll={{ x: 800 }} // Enables horizontal scroll on small screens
      />
    </div>
  );
};

export default UserFeedback;
