import React, { useRef } from "react";
import "./Testimonial.scss";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import TestimoniCard from "../../components/TestimoniCard/TestimoniCard";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Button } from "antd";
const Testimonial = () => {
  const { i18n } = useTranslation();
  const sliderRef = useRef(null);
  const nextSlide = () => {
    sliderRef.current.slickNext();
  };

  const prevSlide = () => {
    sliderRef.current.slickPrev();
  };
  const testimonails = [
    {
      contact: true,
      name:
        i18n.language == "uz"
          ? "Nevrolog bilan maslahatlashish (maslahat davomida tashxis qo'yiladi va kerakli davolash usullari belgilanadi)"
          : "Консультация невролога (на консультации ставится диагноз и назначаются необходимые методы лечения)",
    },
    {
      name:
        i18n.language == "uz"
          ? "Grijani operatsiyasiz davolash bu muolajalar kompleksi Grijani sababini davolash va immunitet yordamida uning surilishiga sabab buladi"
          : "Лечение грыжи без операции (Резорбция грыжи) -это комплекс процедур, устраняющих причину межпозвоночной грыжи и способствующий её рассасыванию с помощью иммунитета.",
      data:
        i18n.language == "uz"
          ? "Ushbu muolajalar kompleksi Grijani operatsiyasiz davolanishning yagona alternativi hisoblanadi va 10 bemordan 9tasida operatsiyasiz davolanishga yordam beradi"
          : "Этот комплекс процедур считается единственной альтернативой операционному лечению грыжи и помогает избавиться от межпозвоночной грыжи у 9 пациентов из 10",
    },
  ];

  // slider
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "40px",
      }}
    >
      <section
        className="testimonail-section section-bg section-common pt-100 pb-70"
        data-aos="fade-up"
        data-aos-duration="2000"
      >
        <div className="container">
          <div style={{ display: "flex", justifyContent: "center" }}>
            <SectionTitle
              title={
                i18n.language == "uz"
                  ? "Bizning xizmatlarimiz:"
                  : "Наши услуги:"
              }
            />
          </div>
          <div className="testimonial-slider-wrapper">
            <Slider {...settings} ref={sliderRef} className="testimoni-slider">
              {testimonails.map((testimonail) => (
                <TestimoniCard testimonail={testimonail} />
              ))}
            </Slider>
            {/* Custom navigation buttons */}
            <Button
              type="primary"
              shape="circle"
              icon={<LeftOutlined />}
              onClick={prevSlide}
              className="slider-nav slider-nav-left"
            />
            <Button
              type="primary"
              shape="circle"
              icon={<RightOutlined />}
              onClick={nextSlide}
              className="slider-nav slider-nav-right"
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default Testimonial;
