import styled from "styled-components";
import { Select } from "antd";

export const AntdSelectLanguage = styled(Select)`
  width: 120px;
  .ant-select-selector {
    border: none !important;
    outline: none !important;
  }
  &:hover {
    border: none !important;
    outline: none !important;
  }
  &:active {
    border: none !important;
    outline: none !important;
  }
`;
