import React from "react";
import "./Footer.scss";
import { FaYoutube, FaInstagram, FaTelegram } from "react-icons/fa";
import call from "../../assets/footer/calling.png";
import time from "../../assets/footer/time.png";
import location from "../../assets/footer/location.png";
import { useTranslation } from "react-i18next";
const Footer = () => {
  const { i18n } = useTranslation();

  const footerContacts = [
    {
      title: i18n.language == "uz" ? "Telefon raqam" : "Номер телефона",
      info: "+998 78 113 83 03",
      icon: call,
    },
    {
      title: i18n.language == "uz" ? "Ish vaqti" : "Рабочее время",
      info: "09:00 AM - 16:00 PM",
      icon: time,
    },
    {
      title: i18n.language == "uz" ? "Manzil" : "Адрес",
      info:
        i18n.language == "uz"
          ? "Qibray tumani , Ofarin 4 . Orientir Qibray rovd"
          : "Кибрайский район, Офарин 4. Ориентир Кибрай ровд",
      icon: location,
    },
  ];

  return (
    <footer className="pt-100 pb-70">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-5">
            <div className="footer-logo"></div>
            <p>
              {i18n.language == "uz"
                ? "Uy sharoitida umurtqa grijalari va boʼgʼim ogʼriqlaridan davolanish"
                : "Лечение болей в спине и суставах в домашних условиях"}
            </p>

            <div className="social-logo">
              <p>
                {i18n.language == "uz"
                  ? " Ijtimoiy tarmoqlar:"
                  : "Социальные сети:"}
              </p>
              <ul>
                <li>
                  <a
                    href="https://www.youtube.com/@EldorNevroDoc"
                    target="_blank"
                  >
                    <FaYoutube />
                  </a>
                </li>
                <li>
                  <a href="https://t.me/eldornevro" target="_blank">
                    <FaTelegram />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/eldornevro.doc/"
                    target="_blank"
                  >
                    <FaInstagram />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-2 col-md-2"></div>
          <div className="col-lg-4 col-md-5">
            <div className="footer-contact">
              <p>{i18n.language == "uz" ? "Aloqa" : "Контакты"}</p>

              {footerContacts.map((footerContact) => {
                return (
                  <div className="contact-list">
                    <div className="contact-icon">
                      <img src={footerContact.icon} alt="call" />
                    </div>
                    <div className="contact-text">
                      <p>{footerContact.title}</p>
                      <h5>{footerContact.info}</h5>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div className="copyright-area">
          <div className="copy-text">
            <p>&copy; eldornevro.</p>
          </div>
          <div className="copy-links"></div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
