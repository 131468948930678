import styled from "styled-components";
export const Container = styled.div`
  flex-direction: column;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  max-width: 1292px;
  margin: 10px 0;
  padding: 0 10px;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
`;
Wrapper.CardWrapper = styled.div`
  width: 260px;
  height: 341px;
  position: relative;
  border-radius: 20px;
  overflow: hidden;
  background-position: center;
  background-image: ${({ img }) =>
    !img
      ? "url(https://optim.tildacdn.com/tild3035-6165-4135-b134-336337303061/-/resize/300x400/-/format/webp/photo.jpg)"
      : `url(${img})`};
  background-size: cover;
`;
Wrapper.Shadow = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
`;
Wrapper.TextWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  top: 270px;
  padding: 0 20px;
  flex-direction: column;
  align-items: center;
  color: #fff;
  transition: all 0.3s;
  ${Wrapper.CardWrapper}:hover & {
    top: 120px;
  }
  h4 {
    color: #fff;
    font-size: 15px;
  }
  p {
    color: #fff;
    font-size: 10px;
    margin: 40px 0 0 0;
  }
`;
