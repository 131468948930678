import React from "react";
import "./blog.scss";
import SectionTitle from "../components/SectionTitle/SectionTitle";
import { useTranslation } from "react-i18next";
import Humans from "../sections/Emergency/humans";
import { WrapperPrice } from "./style";

const Emergency = () => {
  const { i18n } = useTranslation();

  return (
    <div className="wrapper" style={{ padding: "0 0 30px 0" }}>
      <section
        className="emergency-section"
        data-aos="fade-up"
        data-aos-duration="2000"
        style={{ margin: "150px 50px 0px 50px" }}
      >
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-6">
              <img
                width={400}
                src={
                  "https://резорбция38.рф/wp-content/uploads/2023/12/gruzha6.png"
                }
                alt="Emergency"
              />
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="emergency-text">
                <SectionTitle
                  title={
                    i18n.language == "uz"
                      ? " Grijani operatsiyasiz davolash даволаш(Grija rezorbsiyasi)"
                      : "Безоперационное лечение грыжи (резорбция Гриа)"
                  }
                />
                <p>
                  {i18n.language == "uz"
                    ? "- Ushbu muolajalar kompleksi grija sababini davolash va immunitet yordamida uning surilishiga sabab buladi"
                    : "- Этот комплекс процедур лечит причину грии и заставляет ее исчезнуть с помощью иммунной системы."}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <WrapperPrice.Center style={{ margin: "120px 0" }}>
        <WrapperPrice.Card>
          <WrapperPrice.CardDesc>
            {i18n.language == "uz"
              ? "Bizning davolash kursimiz grijani surilishini"
              : "Наш курс лечения ускоряет толчок грижа до "}
          </WrapperPrice.CardDesc>
          <WrapperPrice.CardTitle>2-3</WrapperPrice.CardTitle>
          <WrapperPrice.CardDesc>
            {i18n.language == "uz" ? "marotabagacha tezlashtiradi" : "раз"}
          </WrapperPrice.CardDesc>
        </WrapperPrice.Card>
      </WrapperPrice.Center>

      <Humans />
    </div>
  );
};

export default Emergency;
