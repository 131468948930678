import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
`;
Wrapper.Container = styled.div`
  width: 100%;
  max-width: 800px;
  height: 52px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  background-color: #fff;
  border: 1px solid #ccc;
`;
Wrapper.Item = styled.div`
  cursor: pointer;
  flex: 1;
  height: 100%;
  background-color: ${({ active }) => (active ? "#dbeffa" : "#fff")};
  color: #000;
  font-weight: 500;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${({ start }) => start && "20px 0 0 20px"};
  border-radius: ${({ end }) => end && "0px 20px 20px 0px"};
  border-right: ${({ end }) => !end && "1px solid #ccc"};
`;
Wrapper.Flex = styled.div`
  width: 100%;
  display: flex;
  gap: 34px;
  max-width: 1292px;
  margin-top: 50px;
  padding: 0 10px;
  @media (max-width: 575px) {
    flex-direction: column;
  }
  p {
    font-size: 15px;
  }
`;
Wrapper.HumansWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: space-between;
  justify-content: center;
  flex-direction: column;
  max-width: 700px;
`;
Wrapper.Betwen = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 700px) {
    flex-direction: column;
  }
`;

Wrapper.Center = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0 10px;
`;
Wrapper.Title = styled.div`
  font-size: 70px;
  font-weight: bold;
  color: rgb(84, 123, 108);
  @media (max-width: 700px) {
    font-size: 52px;
  }
`;
Wrapper.Wrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  width: 100%;
  justify-content: center;
`;
Wrapper.ImgBox = styled.div`
  display: flex;
  height: 350px;
  border-radius: 10px;
  @media (max-width: 575px) {
    height: 250px;
    margin: 10px 0;
  }
`;
Wrapper.Img = styled.img`
  width: 50%;
  height: 350px;
  @media (max-width: 575px) {
    height: 250px;
  }
`;
Wrapper.Card = styled.div`
  max-width: 500px;
  width: 100%;
  padding: 50px;
  background-color: #583fbc;
  display: flex;
  align-items: center;
  color: #fff;
  border-radius: 10px;
  flex-direction: column;
`;
Wrapper.CardTitle = styled.div`
  font-size: 80px;
  font-weight: 900;
  text-align: center;
  color: #fff;
`;
Wrapper.CardDesc = styled.div`
  font-size: 30px;
  text-align: center;
  font-weight: 600;
  color: #fff;
`;
Wrapper.Card2 = styled.div`
  max-width: 800px;
  width: 100%;
  padding: 50px;
  background-color: #583fbc;
  display: flex;
  align-items: center;
  color: #fff;
  border-radius: 10px;
  flex-direction: column;
`;
Wrapper.CardTitle2 = styled.div`
  font-size: 40px;
  font-weight: 900;
  text-align: center;
  color: #fff;
  margin: 0 0 10px 0;

  @media (max-width: 575px) {
    font-size: 30px;
  }
`;
