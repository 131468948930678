import React from "react";
import ReactDOM from "react-dom/client";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import "./i18n";
import { Helmet } from "react-helmet";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Helmet>
        <title>Eldor Nevro</title>
        <meta
          name="description"
          content="Umurtqa grijasini operatsiyasiz va dori-darmonsiz davolash. Natijani muolajadan oldingi va keyingi MRTlar bilan koʼrsatamiz"
        />
        <meta
          name="keywords"
          content="neurology, eldornevro, eldor,Eldor,clinic, neurological clinic, health, medicine, Uzbekistan"
        />
        <meta
          property="og:image"
          content="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQDCuoslad_mvI2LKo2tF5wp99UyRkAjhFu7g&s"
        />
        <meta
          property="image"
          content="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQDCuoslad_mvI2LKo2tF5wp99UyRkAjhFu7g&s"
        />

        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://eldornevro.uz/" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://eldornevro.uz/" />
        <meta property="og:title" content={"Eldor Nevro"} />
        <meta
          property="og:description"
          content="Umurtqa grijasini operatsiyasiz va dori-darmonsiz davolash. Natijani muolajadan oldingi va keyingi MRTlar bilan koʼrsatamiz"
        />
      </Helmet>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
