import React, { useEffect, useState } from "react";
import "./Banner.scss";
import { Link } from "react-router-dom";
import icon from "../../assets/banner/icons/Calling.png";
import bannerImg from "../../assets/banner/1.png";
import doctorImg from "../../assets/Eldor-aka-TINIQ-removebg-preview.png";
import bannerPattern from "../../assets/banner/pattern_02.png";
import shapeOne from "../../assets/spine.png";
import shapeTwo from "../../assets/banner/vector_02.png";
import shapeThree from "../../assets/banner/vector_03.png";
import shapeFour from "../../assets/banner/pattern.png";
import { useTranslation } from "react-i18next";
import { Wrapper } from "./style";

const Banner = () => {
  const { i18n } = useTranslation();

  return (
    <section className="section-bg section-common banner-section">
      <div className="d-table">
        <div className="d-table-cell">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div
                  className="banner-text"
                  data-aos="fade-up"
                  data-aos-duration="2000"
                  style={{ padding: "50px 0 0 0" }}
                >
                  <h1>
                    {i18n.language == "uz"
                      ? `Umurtqa grijasini operatsiyasiz va dori-darmonsiz davolash. Natijani muolajadan oldingi va keyingi MRTlar bilan koʼrsatamiz`
                      : "Лечение межпозвоночной грыжи без операции. Подтверждаем результативность лечения  с помощью МРТ до и после."}
                  </h1>
                  {/*  */}
                  {i18n.language == "uz" ? (
                    <div className="flex-wrapper" style={{ margin: "10px 0" }}>
                      <Wrapper.Wrap>
                        <Wrapper.desc>
                          Bizdan shifo topgan bemorlar:
                        </Wrapper.desc>
                      </Wrapper.Wrap>

                      <Wrapper.Wrap>
                        <Wrapper.desc>
                          Bo’yin grijasidan -<span>830+</span>
                        </Wrapper.desc>
                      </Wrapper.Wrap>

                      <Wrapper.Wrap>
                        <Wrapper.desc>
                          Bel grijasidan -<span>1240+</span>
                        </Wrapper.desc>
                      </Wrapper.Wrap>
                      <Wrapper.Wrap>
                        <Wrapper.desc>
                          Migren hurujlaridan - <span>385+</span>
                        </Wrapper.desc>
                      </Wrapper.Wrap>
                      <Wrapper.Wrap>
                        <Wrapper.desc>
                          Rossiya , Hindiston , Xitoy kabi davlatlarda tajriba
                          ortirilgan
                        </Wrapper.desc>
                      </Wrapper.Wrap>
                    </div>
                  ) : (
                    <div className="flex-wrapper" style={{ margin: "10px" }}>
                      <Wrapper.Wrap>
                        <Wrapper.desc>Вылечившиеся пациенты:</Wrapper.desc>
                      </Wrapper.Wrap>

                      <Wrapper.Wrap>
                        <Wrapper.desc>
                          С шейней грыжей - <span>830+ </span>
                        </Wrapper.desc>
                      </Wrapper.Wrap>

                      <Wrapper.Wrap>
                        <Wrapper.desc>
                          С поясничней грыжей - <span>1240+ </span>
                        </Wrapper.desc>
                      </Wrapper.Wrap>

                      <Wrapper.Wrap>
                        <Wrapper.desc>
                          От приступов мигрени -<span>385+ </span>
                        </Wrapper.desc>
                      </Wrapper.Wrap>
                      <Wrapper.Wrap>
                        <Wrapper.desc>
                          Повышение квалификации в России , Индии , Китае
                        </Wrapper.desc>
                      </Wrapper.Wrap>
                    </div>
                  )}

                  {/*  */}
                  <div className="banner-bottom">
                    <div className="theme-btn">
                      <Link to="/contact">
                        {i18n.language == "uz"
                          ? "Maslahat oling"
                          : " Получить консультацию"}
                      </Link>
                    </div>

                    {/* <div className="banner-call">
                      <div className="icon">
                        <img src={icon} alt="icon" />
                      </div>
                      <div className="call-text">
                        <p>ELDOR NEVRO</p>
                        <h6>03 482 394 123</h6>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div
                  className="banner-img-area"
                  data-aos="fade-up"
                  data-aos-duration="2000"
                  data-aos-delay="500"
                >
                  <img src={doctorImg} alt="banner model" width={500} />
                  <div className="info-box">
                    <div className="info-text">
                      <h4 style={{ margin: "10px 0", fontWeight: 700 }}>
                        {i18n.language == "uz"
                          ? "Eldor Аkromovich"
                          : "Эльдор Акромович"}
                      </h4>
                      <p>
                        {i18n.language == "uz"
                          ? "Nevrolog-igloterapevt"
                          : "Невролог-иглотерапевт"}
                      </p>
                      <p>
                        {i18n.language == "uz"
                          ? "Avicenna’s pupil klinikasi rahbari"
                          : "Руководитель клиники Avicenna’s pupil"}
                      </p>
                    </div>
                  </div>

                  {/* Vector Shapes */}
                  <div className="shapes">
                    <img
                      src={shapeOne}
                      alt="shape"
                      style={{
                        borderRadius: "20px",
                        height: "100px",
                        width: "100px",
                      }}
                    />
                    <img src={shapeTwo} alt="shape" />
                    <img src={shapeThree} alt="shape" />
                    <img src={shapeFour} alt="shape" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Banner Pattern Vector*/}
      <img
        className="banner-pattern"
        src={bannerPattern}
        alt="banner pattern"
      />
    </section>
  );
};

export default Banner;
