import React from "react";
import "./TestimoniCard.scss";

const TestimoniCard = ({ testimonail }) => {
  const { img, name, description, ratings } = testimonail;

  return (
    <div className="col-lg-4 single-testimoni" style={{ background: "#" }}>
      <div className="testimonial-card">
        <div style={{ height: "60px" }}></div>

        <div className="testimonial-text">
          <img
            src={img}
            alt=""
            style={{ borderRadius: "10px", border: "2px solid #fff" }}
          />
        </div>
      </div>
    </div>
  );
};

export default TestimoniCard;
