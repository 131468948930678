import React, { useEffect } from "react";
import Navbar from "../components/Navbar/Navbar";
import AboutBanner from "../sections/AboutBanner/AboutBanner";
import Faq from "../sections/Faq/Faq";
import Priority from "../sections/Priority/Priority";
import Safety from "../sections/Safety/Safety";
import Team from "../sections/Team/Team";
import BlogsPage from "./BlogsPage";
import Footer from "../sections/Footer/Footer";
import SectionTitle from "../components/SectionTitle/SectionTitle";
import Doctors from "../sections/Blogs/BlogData";
import BlogCard from "../components/BlogCard/BlogCard";

const About = () => {
  useEffect(() => {
    window.scrollTo({ top: "0px" });
  }, []);
  return (
    <>
      <Navbar />
      <div className="wrapper" style={{ marginBottom: "50px" }}>
        <section>
          <SectionTitle title="Врачи" />
          <h1 style={{ marginBottom: "50px" }}>Врачи</h1>
        </section>
        <div className="container">
          <div className="row">
            {[...Doctors, ...Doctors, ...Doctors].map((blog) => (
              <BlogCard blog={blog} />
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default About;
